
              @import "@/assets/css/variables.scss";
            





























































































// @import '@/assets/css/components/sessionDialog.scss';
.status_session {
  display: flex;
}
