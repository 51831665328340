
              @import "@/assets/css/variables.scss";
            













































































































$active_bg: #0e1236;
#menu,
.menu-shadow,
.menu-wrapper {
  transition: all 0.5s;
}

#menu .menu-shadow {
  width: 100%;
  height: 100%;
  background: $black;
  opacity: 0.5;
  position: fixed;
  @include rtl-sass-prop(left, right, 0);
  @include rtl-sass-prop(right, left, unset);
  top: 0;
  z-index: 100;
}

#menu .menu-wrapper {
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 200;
  background-color: #1c214f;

  .menu-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65px;
    padding: 0 16px;
    background-color: #1c214f;

    /**firefox**/
    .logo {
      display: inline-block;
      width: 190px;
      height: 32px;

      /**firefox**/
      .logo-img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
      .logo_img_small {
        width: 32px;
        height: 32px;
      }
    }

    .menu_back {
      width: 20px;
      height: 20px;
      color: $white;
      cursor: pointer;
    }
  }

  .menu-logo-small {
    height: 64px;
    text-align: center;
    background-color: #1c214f;
    display: flex;
    align-items: center;
    justify-content: center;

    /**firefox**/
    .logo-img {
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
  }
}

.el-menu-vertical {
  border: none;

  .common_menu_item_style {
    width: 224px;
    box-sizing: border-box;
    padding: 0px 8px !important;
    margin: 0 8px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    min-height: 56px;
    white-space: break-spaces;
  }

  .el-menu-item {
    @extend .common_menu_item_style;

    .svg-icon,
    .menu_item_icon {
      width: 24px;
      height: 24px;
      @include rtl-sass-prop(margin-right, margin-left, 4px);
    }
    span {
      font-size: 16px;
      line-height: normal;
      text-transform: uppercase;
    }

    &.is-active {
      background-color: $active_bg !important;
      font-weight: bold;
    }

    &:hover,
    &:active {
      background-color: $active_bg;
    }
  }

  /deep/ .el-submenu {
    .el-submenu__title {
      @extend .common_menu_item_style;

      .title_box {
        width: calc(100% - 18px);
        display: flex;
        align-items: center;

        .svg-icon {
          flex: 0 0 24px;
          height: 24px;
          @include rtl-sass-prop(margin-right, margin-left, 4px);
          // margin-right: 4px;
        }
        .title {
          width: 100%;
          font-size: 16px;
          line-height: normal;
          text-transform: uppercase;
        }
      }

      .el-submenu__icon-arrow {
        font-size: 16px;
        color: $white;
        right: 8px;
      }
    }
    .el-menu {
      // height: auto !important;
      padding: 4px 0 !important;
      background-color: #04071c !important;
      .el-menu-item {
        background-color: #04071c !important;
        height: auto;
        line-height: normal;
        min-height: 40px;
        margin-bottom: 4px;
        &:last-child {
          margin-bottom: 0;
        }
        span {
          font-size: 14px;
        }

        &.is-active {
          background-color: $active_bg !important;
          font-weight: bold;
        }

        &:hover,
        &:active {
          background-color: $active_bg !important;
        }
      }
    }

    &.is-active {
      .el-submenu__title {
        span {
          color: $primary;
          font-weight: bold;
        }
        .svg-icon {
          color: #5ad6cf;
        }
      }
    }
  }
  /deep/ &.el-menu--collapse {
    width: 100%;
    .el-menu-item {
      width: 100%;
      padding: 0;
      margin: 0;

      .el-tooltip {
        display: flex !important;
        align-items: center;
        justify-content: center;

        .svg-icon {
          margin: 0;
        }
      }
    }
    .el-submenu {
      .el-submenu__title {
        width: 100%;
        margin: 0;
        padding: 0 !important;

        .title_box {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .svg-icon {
            margin: 0;
          }
          .title {
            display: none;
          }
        }

        > span,
        .el-submenu__icon-arrow {
          display: none;
        }

        .svg-icon {
          margin: 0;
        }
      }
    }
  }
}
