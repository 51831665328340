/deep/ .el-dialog__body {
  word-break: normal;
  margin: 20px;
  padding: 20px;
  max-height: 80vh;
  overflow-y: scroll;

  p {
    margin-bottom: 30px;
    color: $blue;
  }

  .title {
    font-size: 22px;
    color: $blue;
    margin-bottom: 20px;
  }

  .date {
    font-size: 14px;
    color: $blue;
    margin-bottom: 20px;
    text-align: center;
  }

  a {
    color: $white;
    text-decoration: underline;
  }

  strong {
    font-weight: bold;
  }

  h3 {
    font-size: 1.17em;
  }

  h2 {
    font-size: 1.5em;
  }

  h4 {
    font-size: 1em;
  }

  table {
    display: flex;
    justify-content: center;

    td {
      border: 1px solid #bad4f5;
      padding: 8px;
      color: #bad4f5;
    }
  }
  .arrow {
    background-color: $white;
    text-align: center;
    transform: translateY(-50%);
    border: 0px;
    &:hover {
      background-color: #00f0ff;
    }
  }
  .arrow-left {
    position: absolute;
    top: 50%;
    left: -26px;
    box-shadow: -2px 3px 10px black;
    &.ar-arrow {
      right: -26px;
    }
  }
  .arrow-right {
    position: absolute;
    top: 50%;
    right: -26px;
    box-shadow: 2px 3px 10px black;
    &.ar-arrow {
      left: -26px;
      right: auto;
    }
  }
}

@media (max-width: 767px) {
  /deep/ .el-dialog__body {
    margin: 20px;
    padding: 0;
  }
}

/deep/ .el-dialog__footer {
  .liveChat {
    img,
    span {
      margin-top: 15px;
    }
  }
  .el-carousel__arrow {
    background-color: rgba(0, 0, 0, 0);
    color: $black;
    font-size: 25px;
  }
}

/deep/ {
  .circle-button {
    border-radius: 50%;
    margin: 0 3px 1em;
    height: 2.5em;
    width: 2.5em;
    line-height: 2.7em;
    &:hover {
      cursor: pointer;
    }
  }
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled) {
  background-color: $white !important;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #000d28;
  border: 1px solid #000d28;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #0051ff !important;
  color: $white !important;
}

/deep/ .btn-prev {
  transform: translateY(5px);
}
/deep/ .btn-next {
  transform: translateY(5px);
}

.pagination-div {
  justify-content: flex-end;
  display: flex;
}

.footer {
  flex-wrap: nowrap;
}
